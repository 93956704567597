import React, { useContext } from "react"
import { MyContext } from "../../context"
import Fade from 'react-reveal/Fade';
import Product from "../product/product"
import img1 from "../../assets/img/DSC00756-Recovered_rrr.png"
import img2 from "../../assets/img/fff.png"
import img3 from "../../assets/img/DSC00764-Recovered_tt.png"
import img4 from "../../assets/img/DSC00751-Recovered_tt.png"
import img6 from "../../assets/img/DSC00770-Recovered_tt.png"
import img9 from "../../assets/img/a_Care_for_Pierre.jpg"
import text from "../../data/text"
import "./home.css"


function Home() {
    const context = useContext(MyContext)

    function scrollSlide() {
        window.addEventListener("scroll", () => {
            let scrollPosition = window.pageYOffset
            let bodyScroll = document.querySelector("body").scrollHeight - document.scrollingElement.offsetHeight
            let scrollImg = bodyScroll - scrollPosition
            document.querySelector("#scroll-img1").style.transform = `translateY(${scrollImg + (scrollPosition * 1.2) + "px"})`;
            document.querySelector("#scroll-img2").style.transform = `translateY(${scrollImg + (scrollPosition * 1) + "px"})`;
            document.querySelector("#scroll-img3").style.transform = `translateY(${scrollImg + (scrollPosition * 0.4) + "px"})`;
            document.querySelector("#scroll-img4").style.transform = `translateY(${scrollImg + (scrollPosition * 0.8) + "px"})`;
            document.querySelector("#scroll-img6").style.transform = `translateY(${scrollImg + (scrollPosition * 0.9) + "px"})`;
        })
    }

    scrollSlide()

    return (
        <>
            <div className="scroll-section" style={{ overflow: "hidden" }}>
                <div className="scroll-grid" id="scroll-grid-1">
                    <img src={img4} alt="" id="scroll-img4" width="90%" />
                    <img src={img3} alt="" id="scroll-img3" width="55%" />
                    <img src={img2} alt="" id="scroll-img2" width="75%" />
                    <img src={img6} alt="" id="scroll-img6" width="55%" />
                    <img src={img1} alt="" id="scroll-img1" width="100%" />
                </div>
            </div>

            <div className="section section-home product-section">
                <div className="container w-container">
                    <div className="products-heading">
                        <h1 className="heading home">{text.home.title1[context.state.lang]}</h1>
                    </div>
                    <div className="collection-list-wrapper w-dyn-list">
                        <Product
                            src={require("../../assets/img/product1.jpg")}
                            alt="black leather shoulder bag"
                            name={text.home.favorite1[context.state.lang]}
                            price="1220"
                            link="https://www.etsy.com/listing/1244034589/handmade-handbag-from-three-different?click_key=2038ed08e75e68b39173e0672839e0a28310d0f4%3A1244034589&click_sum=61185127&ref=shop_home_active_1"
                        />
                        <Product
                            src={require("../../assets/img/product2.jpg")}
                            alt="crossbody shoulder bag"
                            name={text.home.favorite2[context.state.lang]}
                            price="270"
                            link="https://www.etsy.com/listing/1204938873/handmade-leather-clutch-modern-pouch-bag?click_key=239940afc2a286335ac6a17242f9c48a55731c87%3A1204938873&click_sum=4f204d0b&ref=shop_home_active_2"
                        />
                        <Product
                            src={require("../../assets/img/product3.jpg")}
                            alt="half round handbag"
                            name={text.home.favorite3[context.state.lang]}
                            price="320"
                            link="https://www.etsy.com/listing/1204923111/handmade-leather-cover-for-notebook-a5?click_key=cf746063dff6d254713a834514d6a4e6135647f4%3A1204923111&click_sum=7ece85a9&ref=shop_home_active_3"
                        />
                        <Product
                            src={require("../../assets/img/product4.jpg")}
                            alt="leather handbag"
                            name={text.home.favorite4[context.state.lang]}
                            price="80"
                            link="https://www.etsy.com/listing/1190985530/handmade-card-case-from-dark-blue?click_key=6fb0cda6a296d652788c7f0e1458ffee8c41c892%3A1190985530&click_sum=e85c16de&ref=shop_home_active_4"
                        />
                    </div>
                </div>
            </div>
            <div className="history">
                <div className="history-div-block">
                    <h1 className="heading history-block">{text.home.title2[context.state.lang]}</h1>
                    <p className="text-block history-block">
                        {text.home.text2[context.state.lang]}
                    </p>
                </div>
            </div>
            <div className="section section-home">
                <div className="centered-container w-container">
                    <Fade top>
                        <h1 className="heading home care">{text.home.title3[context.state.lang]}</h1>
                    </Fade >
                    <div className="gallery-grid-container home">
                        <img src={img9} alt="" className="gallery-thumbnail img" />
                        <Fade right>
                            <div className="text-block-right-home">
                                <div>
                                    {text.home.text3[context.state.lang]}
                                </div>
                            </div>
                        </Fade >
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home